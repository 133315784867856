@font-face {
  font-family: PoppinsRegular;
  src: url('./fonts/Poppins-Regular.ttf');
}
.body-container {
  text-align: center;
  height: 100vh;
  display: grid;
  grid-template-columns: 50% 50%;
}
.left {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.navbar-container {
  height: 10vh;
}
.particles {
  width: 50vw;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
.particlesBlog {
  width: 100vw;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
.middle {
  height: 85vh;
}
.home-container {
  height: 85vh;
  margin: 0 2rem;
}
.resume-container {
  margin: 0 1rem;
}
.project-container {
  height: 85vh;
  box-sizing: border-box;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.project-container .name {
  margin: 0.2rem;
}
.project-container .slider {
  margin: 0.2rem;
}
.footer {
  height: 5vh;
  margin-top: auto;

}
.right {
  position: relative;
  width: 50vw;
  box-sizing: border-box;
  padding: 2rem 0;
  height: 100vh;
}
.rightBlog {
  height: 85vh;
  margin: 0 2rem;
}
.rightContact {
  height: 85vh;
  margin: 0 2rem;
}
.rightHome {
  box-sizing: border-box;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-container img {
  height: 200px;
  width: 200px;
  border-radius: 50%;
}
.intro {
  box-sizing: border-box;
  margin: 2rem 2rem;
  font-size: 1.2rem;
  font-family: PoppinsRegular;
  color: rgb(75, 2, 124);
}
.upper {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: 20% 80%;
  z-index: +5;
  height: 100vh;
  width: 100%;
}
.right .video {
  min-height: 100vh;
  max-width: 50vw;
  z-index: -1;
  object-fit: cover;
}
@media (max-width: 1000px), (width: 1000px) {
  .body-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
  }
  .particles {
    width: 100vw;
  }
  .left {
    display: flex;
    width: 100vw;
    height: auto;
    flex-direction: column;
  }
  .right {
    width: 100vw;
    height: auto;
    padding: 0;
    background-color: #ec6367;
  }
  .middle {
    height: auto;
  }
  .footer {
    display: none;
  }
  .right .project-container {
    display: none;
  }
  .right .rightBlog {
    display: none;
  }
  .left .home-container {
    height: auto;
  }
  .right .rightHome {
    height: auto;
  }
  .home-container {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 2rem;
  }
  .right .rightContact {
    display: none;
  }
}
