.background-video {
  display: flex;
  min-width: 100%;
  min-height: 100%;
  background: black;
  opacity: 1;
}
.background-video::after {
  content: '';
  background-color: rgba(255, 192, 203, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
}

#vid {
  position: fixed;
  top: 0%;
  left: 50%;
  display: flex;
  background: white;
  opacity: 0.7;
  width: 50%;
  height: 100vh;
  object-fit: cover;
}
