.box-container {
  box-sizing: border-box;
  position: relative;
  width: 80%;
  height: 50vh;
  margin: auto;
  z-index: +1;
  font-family: 'Raleway';
  color: #ec6367;
  background: rgba(0, 0, 0, 0);
  border: 2px solid #ec6367;
  border-radius: 25px;
  box-shadow: 1px 1px 5px 2px #888888;
}
.box-container:hover {
  cursor: pointer;
}
.box-container .card {
  border-radius: 25px;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 0.5s ease;
  font-weight: bold;
}
.box-container .card:hover {
  transform: rotateY(180deg);
}
.box-container .card .front {
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.box-container .card .front .image-container {
  width: 75%;
  height: 75%;
}
.flip-message {
  color: #000;
  font-weight: bolder;
  text-shadow: -0.5px 0 rgb(255, 255, 255), 0 0.5px rgb(255, 255, 255),
    0.5px 0 rgb(255, 255, 255), 0 -0.5px rgb(255, 255, 255);
  letter-spacing: 3px;
}
.box-container .card .front .image-container .image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.box-container .card .front .text {
  width: 5%;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  color: #000;
  font-weight: bolder;
  text-shadow: -0.5px 0 rgb(255, 255, 255), 0 0.5px rgb(255, 255, 255),
    0.5px 0 rgb(255, 255, 255), 0 -0.5px rgb(255, 255, 255);
  letter-spacing: 1px;
}
.box-container .card .back {
  box-sizing: border-box;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  color: #333;
  transform: rotateY(180deg);
  background-color: #ec6367;
}
.buttons {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1.5rem;
}
.buttons button {
  width: 15rem;
  padding: 0.5rem;
  margin: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: #ffc0cb;
  background-color: #000;
  border-radius: 2px;
  border: 1px solid #000;
}
.buttons button:hover {
  cursor: pointer;
}
.videoPlayer {
  display: none;
}
.reveal {
  z-index: +2;
  display: flex;
  position: absolute;
  top: -50%;
  left: -50%;
  right: 0;
  bottom: 0;
  background-color: #000;
}
.disable {
  pointer-events: none;
}
