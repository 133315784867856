@font-face {
  font-family: AbrilFatface;
  src: url('../fonts/AbrilFatface-Regular.ttf');
}
@font-face {
  font-family: MontserratBold;
  src: url('../fonts/Montserrat-Bold.ttf');
}
@font-face {
  font-family: RalewayBlack;
  src: url('../fonts/Raleway-Black.ttf');
}
.home {
  text-decoration: none;
  box-sizing: border-box;
  padding: 0.5rem;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.home span {
  color: rgb(75, 2, 124);
  text-shadow: none;
}
.animationText:before {
  content: ' ';
  /* so that safari can understand */
  animation-name: animate;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  -webkit-animation-name: animate;
  -webkit-animation-duration: 8s;
  -webkit-animation-iteration-count: infinite;
}
@-webkit-keyframes animate {
  0% {
    content: 'Building Things! 🖥️';
  }
  25% {
    content: 'Problem Solving! 👨‍💻️';
  }
  50% {
    content: 'Sports! ⚽';
  }
  75% {
    content: 'Tech World! 📱';
  }
  100% {
    content: 'Market and Economics! 📈';
  }
}
@keyframes animate {
  0% {
    content: 'Building Things! 🖥️';
  }
  25% {
    content: 'Problem Solving! 👨‍💻️';
  }
  50% {
    content: 'Sports! ⚽';
  }
  75% {
    content: 'Tech World! 📱';
  }
  100% {
    content: 'Market and Economics! 📈';
  }
}
.header {
  box-sizing: border-box;
  font-size: 2rem;
  font-family: RalewayBlack;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  letter-spacing: 3px;
  color: rgb(255, 255, 255);
  text-align: left;
}
